.bhdLno {
    max-width: 400px !important;
    max-height: 400px !important;
    transform: rotate(-45deg);
    position: none;
}

.bhdLno img {
    display: none;
}
