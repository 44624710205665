@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    margin: 0;
    width: 100%;
    height: 100%;
    color: #495057;
    background: white;

    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #011e3d5f #f6f8fa; /* scroll thumb and track */
}

html {
    overflow: hidden;
    width: 100%;
}

body {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-family: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input {
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */
}

input[type='checkbox'] {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
}

select:focus,
input:focus {
    outline: none;
    @apply focus:ring-0 focus:ring-offset-0;
}

* {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

::-moz-range-thumb {
    appearance: none;
    -moz-appearance: textfield;
    background-color: #011e3d;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-top: 1px;
}

::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #011e3d;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-top: 1px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f6f8fa;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #011e3d2f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #011e3d5f;
}

@layer utilities {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button,
    input[type='number'] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
    }
}

.desktop {
    position: relative;
    background-color: white;
    max-width: 450px;
    margin: auto !important;
}

/**
 * Efecto de carga para los botones con la propiedad
 * loading = true
 */
.button span {
    display: flex;
}

.button span b {
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: stretch;
    animation-play-state: running;
    animation-timing-function: ease-out;
    animation-delay: 0.1s;
    display: block;
    border-radius: 100%;
    height: 5px;
    width: 5px;
    margin: 0 8px;
}

.button span b:first-child {
    animation-delay: 0s;
    margin: 0;
}

.button span b:last-child {
    animation-delay: 0.3s;
    margin: 0;
}

@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: rgba(white, 0.6);
    }
    50% {
        background-color: rgba(white, 0.8);
    }
    100% {
        transform: scale(1);
        background-color: white;
    }
}

/** 
 * Estilos para eliminar elemntos del contrato
 */
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
    display: none !important;
}

/**
 * Ventana de carga... Efecto loading
 */
.loader {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border-left: 4px solid #33caff;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader_solo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #fff;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.react-player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.pac-container:after {
    content: "Can't find your address?";
    @apply flex items-center justify-between px-1 py-6 text-xs text-blue-kiwi font-semibold;
}

@media only screen and (min-width: 640px) {
    body {
        background-color: #e9ecef;
        padding: 24px 0px;
    }

    .desktop {
        border-radius: 16px;
        overflow: auto;
    }
}

@media screen and (min-height: 1000px) {
    body {
        display: flex;
    }
}

/**
 * Ocultar el modal que abre la integración con ATH
 * para mantener la consistencia de UI en la aplicación...
 */
.athm-modal-principal {
    display: none !important;
}
