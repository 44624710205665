/**
 * Navegación ===================================
 */
.react-calendar__navigation {
    display: flex;
    padding: 24px 0px;
}

.react-calendar__navigation__label {
    pointer-events: none;
}

/** Felchas cambio de mes */
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    font-size: 32px;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    color: #6c757d;
    border: 1px solid #e4e4e7;
    display: flex;
    justify-content: center;
    align-items: center;
}

/** Flechas cambio de año... */
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none;
}

/** Título mes actual... */
.react-calendar__navigation__label__labelText--from {
    font-weight: 600;
    color: #011e3d;
}

/**
 * Contenedor ===================================
 */
.react-calendar__viewContainer {
    height: 345px;
}

.react-calendar__month-view__weekdays {
    color: #6c757d;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    padding: 8px 0px;
    margin-bottom: 16px;
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.react-calendar__month-view__days__day {
    font-weight: 500;
    margin: 8px 0px;
    color: #64748b;
    height: 32px;
}

.react-calendar__month-view__days__day:disabled {
    color: #cbd5e1;
}

.react-calendar__month-view__days__day abbr {
    height: 32px;
}

/** Dias del mes anterior y posterior */
.react-calendar__month-view__days__day--neighboringMonth {
    color: #cbd5e1;
    visibility: hidden;
}

.react-calendar__tile--active abbr,
.react-calendar__tile--now abbr {
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

.react-calendar__tile--now abbr {
    background-color: #cbd5e1;
    color: #043960;
}

.react-calendar__tile--active abbr {
    background-color: #043960;
    color: white;
}
